import BreadcrumbsProps from "./BreadcrumbsProps";
import { FunctionComponent } from "react";
import UIText from "../text/UIText";
import Icon from "../icon/icon";

const breadcrumbsStyles = {
  display: "flex",
  flexDirection: "row",
  gap: "6px",
  justifyContent: "center",
  alignItems: "center",
};

const breadcrumbStyles = {
  display: "flex",
  flexDirection: "row",
  gap: "4px",
  justifyContent: "center",
  alignItems: "center",
};

const Breadcrumbs: FunctionComponent<BreadcrumbsProps> = ({ options }) => {
  return (
    <div style={breadcrumbsStyles as React.CSSProperties}>
      {options &&
        options?.length > 0 &&
        options.map((option, index) => {
          const { label, link, icon } = option;
          return (
            <>
              <div key={label} style={breadcrumbStyles as React.CSSProperties}>
                {icon}
                {label !== undefined && <UIText text={label} link={link} />}
              </div>
              {index + 1 < options.length && <Icon name="KeyboardArrowRight" />}
            </>
          );
        })}
    </div>
  );
};

export default Breadcrumbs;
