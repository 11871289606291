import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  IconButton,
  Typography,
  Tooltip,
} from "@mui/material";
import Edit from "@mui/icons-material/Edit";
import Visibility from "@mui/icons-material/Visibility";
import { UITableProps } from "./UITableProps";
import UIChip from "../chips/UIChip";
import colors from "@/utils/colorsV2";
import UIPagination from "../pagination/UIPagination";
import UIText from "../text/UIText";
import Status, { estados } from "@/utils/processStatus";
import { Replay } from "@mui/icons-material";
import UITooltip from "../tooltip/UITooltip";
import UIMenuActions from "../menu/menu-actions/UIMenuActions";

const UITable: React.FC<UITableProps> = ({
  columns,
  rows,
  actions,
}) => {
  const rowsNumber = 20;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(rowsNumber);

  const changePage = (page: number) => {
    setPage(page - 1);
  };


  return (
    <TableContainer
      component={Paper}
      sx={{
        width: "100%",
        position: "relative",
        height: "calc(100% - 88px)",
      }}
    >
      <Table
        stickyHeader
        sx={{
          borderColor: colors.grayLM200,
          height: "100%",
          position: "relative",
        }}
      >
        <TableHead>
          <TableRow>
            {columns.length > 0 ? (
              columns.map((column) => {
                if (column.id === "idEstatus") {
                  return <></>
                }
                return (
                  <TableCell
                    sx={{
                      padding: "12px 24px",
                      verticalAlign: "middle",
                      backgroundColor: colors["blue-2"],
                      ...(["status", "actions"].includes(column.id) && {
                        minWidth: "120px",
                      }),
                    }}
                    key={column.id}
                  >
                    <UIText
                      text={column.label}
                      size="text-xs"
                      color="gray600"
                    />
                  </TableCell>
                );
              })
            ) : (
              <Typography variant="subtitle1" sx={{ color: colors.grayLM200 }}>
                No hay columnas
              </Typography>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : rows
          ).map((row, index) => (
            <TableRow
              sx={{
                verticalAlign: "super",
              }}
              key={index}
            >
              {columns.map((column) => {
                switch (column.id) {
                  case "empresa":
                    return (
                      <TableCell
                        size="small"
                        key={column.id}
                        sx={{ padding: "16px 24px" }}
                      >
                        {row["idSiebelAserta"] && (
                          <UIText
                            text={`Aserta ID ${row["idSiebelAserta"]}`}
                            size="text-s"
                            color="gray600"
                            weight="medium"
                            sx={{ minWidth: "190px" }}
                          />
                        )}
                        {row["idSiebelInsurgentes"] && (
                          <UIText
                            text={`Insurgentes ID ${row["idSiebelInsurgentes"]}`}
                            size="text-s"
                            color="gray600"
                            weight="medium"
                            sx={{ minWidth: "190px" }}
                          />
                        )}
                      </TableCell>
                    );
                  case "nombre":
                    return (
                      <TableCell size="small" key={column.id}>
                        <UIText
                          text={row[column.id]}
                          size="text-s"
                          color="blue"
                        />
                      </TableCell>
                    );
                  case "estatus":
                    const statusVariants: { [key: string]: string } = {
                      [Status.IN_SIGNING]: "secondary",
                      [Status.VERIFICATION]: "success",
                      [Status.VALIDATING_GUARANTOR]: "info",
                    };

                    return (
                      <TableCell
                        size="small"
                        key={column.id}
                        sx={{ textAlign: "center", padding: "16px 24px" }}
                      >
                        <UIChip
                          type={row[column.id]?.toString()?.toLowerCase()?.includes("error") ? "error" : statusVariants[row[column.id]] as any}
                          label={row[column.id]?.toString()?.length > 25 ? row[column.id].toString().substring(0, 25) + "..." : row[column.id]?.toString()}
                        />
                      </TableCell>
                    );
                  case "proccess":
                    return (
                      <TableCell
                        size="small"
                        sx={{ textAlign: "center", padding: "16px 24px" }}
                        key={column.id}
                      >
                        {row[column.id]}
                      </TableCell>
                    );
                  case "creditLine":
                    return (
                      <TableCell
                        size="small"
                        sx={{ padding: "16px 24px" }}
                        key={column.id}
                      >
                        <p>
                          <span>$ - - - </span>
                          <br />
                          <span style={{ color: "green" }}>$ - - - </span>
                        </p>
                      </TableCell>
                    );
                  case "actions":
                    return (
                      <TableCell
                        sx={{ padding: "16px 24px", textAlign: "center" }}
                        size="small"
                        key={column.id}
                      >
                        <UIMenuActions
                          actions={actions}
                          solicitud={row}
                        />
                      </TableCell>
                    );
                  case "idEstatus":
                    return <></>
                  default:
                    return (
                      <TableCell
                        sx={{ padding: "16px 24px" }}
                        size="small"
                        key={column.id}
                      >
                        <UIText
                          text={row[column.id]}
                          size="text-s"
                          color="gray600"
                        />
                      </TableCell>
                    );
                }
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {rows.length > 0 && (
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          sx={{
            position: "sticky",
            bottom: "0",
            width: "100%",
            padding: "12px 24px 16px 24px",
            backgroundColor: "white",
            borderTop: `1px solid ${colors.grayLM200}`,
          }}
        >
          <UIPagination
            total={rows.length}
            perPage={rowsNumber}
            currentPage={page + 1}
            onPageChange={changePage}
          />
        </Box>
      )}
    </TableContainer>
  );
};

export default UITable;
