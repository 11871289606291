import { IconButton, Typography, Button, styled } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import colors from "@/utils/colorsV2";
import UIText from "../text/UIText";

const StyledBox = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
});

const StyledButton = styled(Button)({
  width: "40px",
  height: "40px",
  padding: "0",
  minWidth: "0",
  fontSize: "14px",
  backgroundColor: colors.white,
  color: colors.grayDM600,
  border: `1px solid ${colors.grayLM50}`,
  "&:hover": {
    backgroundColor: colors.grayLM50,
    border: `1px solid ${colors.grayDM600}`,
  },
  "&:disabled": { color: colors.grayLM800, backgroundColor: colors.grayLM50 },
});

const StyledIconButton = styled(IconButton)({
  color: colors.asertaBlue,
  fontSize: "14px",
  height: "32px",
  maxHeight: "32px",
  padding: "6px 16px",
  gap: "4px",
  borderRadius: "8px",
  border: `1px solid ${colors.asertaBlue}`,
  background: "#FFF",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  "&:hover": { backgroundColor: "inherit" },
  "& svg": { marginRight: "5px", marginLeft: "5px" },
});

const CenteredBox = styled("div")({
  display: "flex",
  justifyContent: "center",
  gap: "4px",
  width: "40%",
});

const Pagination: React.FC<PaginationProps> = ({
  total,
  perPage,
  currentPage,
  onPageChange,
}) => {
  const totalPages = Math.ceil(total / perPage);

  const generatePages = () => {
    let pages = [];
    let startPage = 1;
    let endPage = totalPages;

    if (totalPages <= 5) {
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }
    } else {
      pages.push(startPage);
      if (currentPage > 3) {
        pages.push("...");
      }
      let middleStart = Math.max(2, currentPage - 2);
      let middleEnd = Math.min(currentPage + 2, totalPages - 1);
      for (let i = middleStart; i <= middleEnd; i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) {
        pages.push("...");
      }
      pages.push(endPage);
    }

    return pages;
  };

  const pages = generatePages();

  return (
    <StyledBox>
      <StyledIconButton
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        <ArrowBackIcon />
        <UIText text={"Anterior"} />
      </StyledIconButton>
      <CenteredBox>
        {pages.map((page, index) => (
          <StyledButton
            key={index}
            onClick={() => typeof page === "number" && onPageChange(page)}
            disabled={currentPage === page}
          >
            {page}
          </StyledButton>
        ))}
      </CenteredBox>
      <StyledIconButton
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        <UIText text={"Siguiente"} />
        <ArrowForwardIcon />
      </StyledIconButton>
    </StyledBox>
  );
};

export default Pagination;
