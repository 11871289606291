import { GetFilesToBeValidatedToSingnatureRequest, GetFilesToBeValidatedToSingnatureResponse,  } from "@/services/get-files-to-be-validated-for-signature/getFilesToBeValidatedForSignatureService";

export function useFilesToBeValidatedForSignature() {
  const FilesToBeValidated = async (req: GetFilesToBeValidatedToSingnatureRequest) => {

    const result = await fetch(`/api/files-to-be-validated-for-signature`, {
      body: JSON.stringify(req),
      method: "POST",
    });
    const { data } = await result.json();
    const resultJson = data as GetFilesToBeValidatedToSingnatureResponse;
    return resultJson;
  };
  return FilesToBeValidated;
}
