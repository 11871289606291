import React, { FC } from "react";
import * as Icons from "@mui/icons-material";
import IconProps from "./icon.props";
import colors from "@/utils/colorsV2";
import radius from "@/utils/radius";
import {Property} from "csstype";
const defaultColor = "primary";
const defaultSize = "normal";
const defaultVariant = "normal";

const defaultRootStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  boxSizing: 'border-box',
  border: '2px solid transparent',
  borderRadius: radius.full,
}

const colorStyles = {
  primary: {
    color: colors.asertaBlue,
  },
  secondary: {
    color: colors.asertaBlueGray,
  },
  info: {
    color: colors.grayDM500,
  },
  warning: {
    color: colors.warning500,
  },
  success: {
    color: colors.success500,
  },
  error: {
    color: colors.error500,
  },
  white: {
    color: colors.white,
  },
  black: {
    color: colors.black,
  },
  gray: {
    color: colors.grayLM800
  },
  orange: {
    color: colors.asertaOrange
  },
  blue: {
    color: colors.asertaBlue
  }
};

const rootSizeStyles = {
  small: {
    width: "22px",
    height: "22px",
  },
  tiny: {
    width: "36px",
    height: "36px",
  },
  normal: {
    width: "30px",
    height: "30px",
  },
  medium: {
    width: "42px",
    height: "42px",
  },
  large: {
    width: "54px",
    height: "54px",
  },
};

const iconSizeStyles = {
  small: {
    width: "16px",
    height: "16px",
  },
  tiny: {
    width: "20px",
    height: "20px",
  },
  normal: {
    width: "24px",
    height: "24px",
  },
  medium: {
    width: "36px",
    height: "36px",
  },
  large: {
    width: "48px",
    height: "48px",
  },
};

const variantStyles = (color: string = defaultColor) => {
  return {
    normal: {
      border: "2px solid transparent",
    },
    border: {
      border: `2px solid ${color}`,
      borderRadius: radius.full,
    },
    background: {
      border: "2px solid transparent",
      borderRadius: radius.full,
      backgroundColor: colors.grayLM300,
    },
  };
};

const Icon: FC<IconProps> = ({
  name,
  color = defaultColor,
  size = defaultSize,
  variant = defaultVariant,
  onClick
}) => {
  
  const rootStyles = {
    ...defaultRootStyles,
    ...colorStyles[color],
    ...(variant !== "normal" && {...rootSizeStyles[size]}),
    ...variantStyles(colorStyles[color]?.color)[variant],
  };
  const iconStyles = {
    ...colorStyles[color],
    ...iconSizeStyles[size],
  };
  let IconSelected = Icons.Gradient;
  if (name && Icons[name]) {
    IconSelected = Icons[name];
  }

  return <div style={rootStyles as React.CSSProperties}>
    <IconSelected onClick={onClick} sx={iconStyles}  />
  </div>;
};

export default Icon;
