import { FC, useEffect, useState } from "react";
import { Box, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import UIText from "@/components/text/UIText";
import Image from "next/image";
import colors from "@/utils/colorsV2";
import { RowTable } from "@/components/table/UITableProps";
import Visibility from "@mui/icons-material/Visibility";

export interface Action {
  label: string;
  default?: boolean;
  onClick: (row: RowTable) => Promise<void>;
  icon?: string;
  disabled: (row: RowTable) => boolean;
}

interface UIMenuActionsProps {
  actions: Action[];
  solicitud: RowTable
}

interface IconImgProps {
  icon: string;
  disabled: boolean;
}
const IconImg: FC<IconImgProps> = ({ icon, disabled = false }) => {
  return <Image src={icon} alt="icon" width={16} height={16} color={disabled ? colors.grayLM200 : colors.asertaBlueGray} />
}

const UIMenuActions: FC<UIMenuActionsProps> = ({ actions, solicitud }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [defaultAction, setDefaultAction] = useState<Action>({ label: "", onClick: async () => { }, disabled: () => false, default: true });
  const [actionsList, setActionsList] = useState<Action[]>([]);
  const [disabled, setDisabled] = useState<boolean>(false);

  useEffect(() => {
    const defaultAction = actions.find(action => action.default);
    if (defaultAction) {
      setDefaultAction(defaultAction);
      setActionsList(actions.filter(action => !action.default));
    } else {
      setActionsList(actions);
    }
    if (solicitud)
      setDisabled(actions.filter(action => !action.default).every(action => action.disabled(solicitud)));
  }, [actions, solicitud]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const clickHandler = (action: Action) => {
    handleClose();
    action.onClick(solicitud);
  }

  return <Box sx={{ display: 'flex', gap: '2' }}>
    {defaultAction.label !== "" && <Tooltip
      title={<UIText text={defaultAction.label} color="white" weight="semibold" size="text-xs" />}
      placement="top"
      arrow
    >
      <Box component={'span'}>
        <IconButton
          onClick={() => defaultAction.onClick(solicitud)}
          disabled={defaultAction.disabled(solicitud)}>
          <Visibility
            sx={{ color: defaultAction.disabled(solicitud) ? colors.grayDM200 : colors.asertaBlue, }}
            fontSize="small"
          />
        </IconButton>
      </Box>
    </Tooltip>}
    {actionsList.length === 1 && <Tooltip
      title={<UIText text={actionsList[0].label} color="white" weight="semibold" size="text-xs" />}
      placement="top"
      arrow
    >
      <IconButton
        onClick={() => actionsList[0].onClick(solicitud)}
        disabled={actionsList[0].disabled(solicitud)}>
        {actionsList[0].icon && <IconImg icon={actionsList[0].icon} disabled={actionsList[0].disabled(solicitud)} />}
      </IconButton>
    </Tooltip>}
    {actionsList.length > 1 && <>
      <Tooltip
        title={<UIText text={"Ver mas acciones"} color="white" weight="semibold" size="text-xs" />}
        placement="top"
        arrow
      >
        <Box component={'span'}>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            disabled={disabled}
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
        </Box>
      </Tooltip>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            style: {
              maxHeight: 48 * 4.5,
              width: 'auto',
              backgroundColor: colors.white,
            },
          },
        }}
      >
        {actionsList.map((action) => (
          <MenuItem key={action.label} onClick={() => { clickHandler(action) }} disabled={action.disabled(solicitud)}>
            <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center', justifyContent: "space-between" }}>
              {action.icon && <IconImg icon={action.icon} disabled={action.disabled(solicitud)} />}
              <UIText text={action.label} color="blueGray" size="text-xs" weight="semibold" />
            </Box>
          </MenuItem>
        ))}
      </Menu></>
    }
  </Box>
};

export default UIMenuActions;