import { CurentUserRequest, CurrentUsersResponse } from "@/services/get-current-user/getCurrentUserService";

export function useGetUserFromCurrentSession() {
  const getUserFromCurrentSession = async () => {
    const result = await fetch(`/api/get-current-user`, {
      method: "GET",
    });
    const { data } = await result.json();
    const resultJson = data as CurrentUsersResponse;
    return resultJson;
  };
  return getUserFromCurrentSession;
  
}