import { FunctionComponent } from "react";
import colors from "@/utils/colorsV2";
import { Chip, ChipProps, styled } from "@mui/material";
import UIChipProps from "./UIChipProps";
import UIText from "../text/UIText";

const success = {
  backgroundColor: colors.green2,
  borderColor: colors.green1,
  color: colors.success700
};

const primary = {
  backgroundColor: colors.grayLM50,
  borderColor: colors.grayLM200,
  color: colors.grayLM700

};

const secondary = {
  backgroundColor: colors.asertaOrange,
  borderColor: colors.asertaOrange,
  color: colors.grayLM700

};

const info = {
  backgroundColor: colors.blue4,
  borderColor: colors.blue3,
  color: colors.blue5

};

const error = {
  backgroundColor: colors.red2,
  borderColor: colors.red1,
  color: colors.red3

};

const colorStyles = {
  success: success,
  primary: primary,
  secondary: secondary,
  info: info,
  error: error,
};

const ColorChip = styled(Chip)<ChipProps>(({ color = "primary" }) => {
  const _colorStyles = colorStyles[color as keyof typeof colorStyles];
  return {
    color: _colorStyles.color,
    backgroundColor: _colorStyles.backgroundColor,
    borderColor: _colorStyles.borderColor,
    fontSize: "12px",
    height: "auto",
    "& .MuiChip-icon": {
      order: 1,
      marginLeft: "-8px",
      marginRight: "5px",
      fontSize: "14px",
    },
    "& .MuiChip-label": {
      padding: "2px 8px",
    },
  };
});

const textColors = {
  success: "success",
  primary: "gray",
  secondary: "white",
  info: "blue5",
  error: "red3",
} as const;

const UIChip: FunctionComponent<UIChipProps> = ({ type, label, icon }) => {
  return (
    <ColorChip
      label={
        <UIText
          text={label}
          size="text-xs"
          {...(type && { color: textColors[type] })}
        />
      }
      icon={icon}
      variant="outlined"
      color={type}
    />
  );
};

export default UIChip;
