import { InfoFiado } from "@/context/infoFiadoContext";
import { GetFilesToBeValidatedResponse } from "@/services/get-files-to-be-validated/getFilesToBeValidatedService";

export const parseFileToBeValidate = (dataFiles:GetFilesToBeValidatedResponse) => {
  const result: InfoFiado = dataFiles.reduce((acc, file) => {
        const owner = file.pertenencia;
        const ownerData = owner?.split("-");
        const ownerType = ownerData![0];
        if(owner?.includes( 'Fiado')) {
          if(!acc.fiado) {
            acc.fiado = { files: [], filesRL: [], moralPerson: false, validated: false};
          }

          if(owner.includes('RepresentanteLegal')) {
            const index = parseInt(ownerData!.pop()!, 10);
            if(!acc.fiado!.filesRL[index]) {
              acc.fiado!.filesRL[index] = [];
            }
            acc.fiado!.filesRL[index].push(file);
          } else {
            acc.fiado!.files.push(file);
          }

          //acc.fiado.moralPerson = acc.fiado!.filesRL.length > 0;

        } else {
          if(!acc.obligado) {
            acc.obligado = [];
          }
          const indexY = parseInt(ownerData!.pop()!, 10);
          const indexX = parseInt(ownerData!.pop()!, 10);
          if(owner?.includes( 'RepresentanteLegal')) {
            if(!acc.obligado[indexX]) {
              acc.obligado[indexX] = { files: [], filesRL: [], moralPerson: false, validated: false};
            }
            if(!acc.obligado[indexX].filesRL[indexY]) {
              acc.obligado[indexX].filesRL[indexY] = [];
            }

            acc.obligado[indexX].filesRL[indexY].push(file);
          } else {
            if(!acc.obligado[indexY]) {
              acc.obligado[indexY] = { files: [], filesRL: [], moralPerson: false, validated: false};
            }
            acc.obligado[indexY].files.push(file);
          }
        }
        return acc;
      }, {} as InfoFiado); 
  return result;
}


export const returnToList = (data: InfoFiado) => {
  let result = [...data.fiado?.files ?? [], ...data.fiado?.filesRL?.flat() ?? []];

  data.obligado?.forEach((item) => {
    result = [...result, ...item.files, ...item.filesRL.flat()];
  });
  return result;
}