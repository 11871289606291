import { parseFileToBeValidate } from "@/utils/parsers";
import { useFilesToBeValidated } from "./useFilesToBeValidated";
import { Current } from "@/context/infoFiadoContext";
import { useFilesToBeValidatedForSignature } from "./useFilesToBeValidatedForSignature";

export const useRequest = () => {
  const filesToBeValidated = useFilesToBeValidated();
  const getInfoFiado = async (idProceso: string) => {
    const result = await filesToBeValidated({ idProceso });
    const tempInfoFiado = parseFileToBeValidate(result);
    return tempInfoFiado;
  }

  const filesToBeValidatedForSignature = useFilesToBeValidatedForSignature();
  const getFilesToSign = async (idProcess: string) => {
    const result = await filesToBeValidatedForSignature({ idProcess });
    return result;
  }

  return { getInfoFiado, getFilesToSign };
}