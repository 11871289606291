import { GetFilesToBeValidatedRequest, GetFilesToBeValidatedResponse } from "@/services/get-files-to-be-validated/getFilesToBeValidatedService";

export function useFilesToBeValidated() {
  const FilesToBeValidated = async (req: GetFilesToBeValidatedRequest) => {

    const result = await fetch(`/api/files-to-be-validated`, {
      body: JSON.stringify(req),
      method: "POST",
    });
    const { data } = await result.json();
    const resultJson = data as GetFilesToBeValidatedResponse;
    return resultJson;
  };
  return FilesToBeValidated;
}
